@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

.datePicker {
  border-radius: 2.3px;
  border: 1px solid #9ca9bf49;
  background-color: transparent;
  color: black;
  padding: 3px;
  /* max-width: 23rem; */
}

.text {
  width: 99%;
  height: 99%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(50, 50, 50, 0.9);
  z-index: 10;
  text-align: center;
  justify-content: center;
  padding-top: 1rem;
  display: none;
}

.rcode:hover .text {
  display: block;
  cursor: pointer;
}

.btn {
  background-color: #4b8df8;
  outline: none !important;
  background-image: none !important;
  filter: none;
  box-shadow: none;
  text-shadow: none;
  text-align: center;
}

::-moz-selection {
  background-color: #009bff;
  color: white;
}

::selection {
  background-color: #009bff;
  color: white;
}

.filedrop {
  width: 200px;
  height: 100px;
  background-color: red;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.fullscreen-enabled {
  background-color: #fcfcfc;
  overflow: auto;
  position: relative;
}

.newcon:hover {
  width: 100%;
  height: 50px;
  background-color: #009bff;
}

.rich-text-table {
  border-collapse: collapse;
  width: 100%;
}

.rich-text-table td,
.rich-text-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.rich-text-table th {
  background-color: #ddd;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.fill-in-the-blank + input {
  border: '1px solid #4b8df8';
  border-color: #4b8df8;
  outline: #4b8df8;
}

